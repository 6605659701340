<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Recent works</h2>

  <div class="spacer-60"></div>

  <!-- portfolio filter (desktop) -->
  <ul class="portfolio-filter list-inline wow fadeInUp">
    <li class="list-inline-item" [ngClass]="{ current: workFilter === 'all' }" (click)="workFilter = 'all'">Everything
    </li>
    <li class="list-inline-item" [ngClass]="{ current: workFilter === 'eleveur-connect' }"
      (click)="workFilter = 'eleveur-connect'">
      Eleveur Connect</li>
    <li class="list-inline-item" [ngClass]="{ current: workFilter === 'fantom-tribune' }"
      (click)="workFilter = 'fantom-tribune'">Fantom
      Tribune</li>
    <li class="list-inline-item" [ngClass]="{ current: workFilter === 'captain-tower' }"
      (click)="workFilter = 'captain-tower'">
      Captain Tower</li>
    <li class="list-inline-item" [ngClass]="{ current: workFilter === 'Spookyswap' }"
      (click)="workFilter = 'Spookyswap'">
      Spookyswap</li>
  </ul>

  <!-- portfolio filter (mobile) -->
  <div class="pf-filter-wrapper">
    <select class="portfolio-filter-mobile" (change)="onChange($event)">
      <option value="all">Everything</option>
      <option value="eleveur-connect">Eleveur-connect</option>
      <option value="fantom-tribune">Fantom-tribune</option>
      <option value="captain-tower">Captain-tower</option>
      <option value="Spookyswap">Spookyswap</option>
    </select>
  </div>

  <!-- portolio wrapper -->
  <div class="row portfolio-wrapper">




    <!-- portfolio item -->
    <div class="col-md-4 col-sm-6 grid-item eleveur-connect captain-tower"
      *ngIf="workFilter === 'all' || workFilter === 'eleveur-connect' ">
      <a href="javascript:" class="work-content" data-toggle="modal" (click)="singleGallery.open(0)">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Eleveur-connect</span>
            <h4 class="title">User Demo Site Homepage</h4>
            <span class="more-button"><i class="icon-magnifier-add"></i></span>
          </div>
          <div class="thumb">
            <app-gallery #singleGallery [albums]="singleGallery2"></app-gallery>

            <!-- <img src="assets/images/works/eleveur1.JPG" alt="Portfolio-title" /> -->
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>


    <div class="col-md-4 col-sm-6 grid-item eleveur-connect captain-tower"
      *ngIf="workFilter === 'all' || workFilter === 'eleveur-connect' ">
      <a href="javascript:" class="work-content" data-toggle="modal" (click)="singleGallery.open(0)">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Eleveur-connect</span>
            <h4 class="title">Support Ticket Dashboard</h4>
            <span class="more-button"><i class="icon-magnifier-add"></i></span>
          </div>
          <div class="thumb">
            <app-gallery #singleGallery [albums]="singleGallery3"></app-gallery>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>


    


    <!--     <app-ui-modal #exampleModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true"
      [hideFooter]="true">
      <div class="app-modal-body">
        <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
          <img src="assets/images/single-work.svg" alt="Title" />
          <h2>Guest App Walkthrough Screens</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam hendrerit nibh in massa semper rutrum. In
            rhoncus eleifend mi id
            tempus.
          </p>
          <p>Donec consectetur, libero at pretium euismod, nisl felis lobortis urna, id tristique nisl lectus eget
            ligula.</p>
          <a href="javascript:" class="btn btn-default">View on Dribbble</a>
        </div>
      </div>
    </app-ui-modal> -->

    <!-- portfolio item -->
    <!--   <div class="col-md-4 col-sm-6 grid-item Spookyswap" *ngIf="workFilter === 'all' || workFilter === 'Spookyswap'">
      <a href="javascript:" class="work-video">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Spookyswap</span>
            <h4 class="title">Delivery App Wireframe</h4>
            <span class="more-button"><i class="icon-camrecorder"></i></span>
          </div>
          <div class="thumb">
            <ng-image-slider [images]="videoObject" #nav></ng-image-slider>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div> -->

        <!-- portfolio item -->
        <div class="col-md-4 col-sm-6 grid-item eleveur-connect"
        *ngIf="workFilter === 'all' || workFilter === 'eleveur-connect'">
        <a href="javascript:" class="work-video" (click)="singleGallery.open(0)">
          <div class="portfolio-item rounded shadow-dark">
            <div class="details">
              <span class="term">Eleveur-connect</span>
              <h4 class="title">Eleveur Connect Home Page</h4>
              <span class="more-button"><i class="icon-magnifier-add"></i></span>
            </div>
            <div class="thumb">
              <app-gallery #singleGallery [albums]="singleGallery7"></app-gallery>
              <div class="mask"></div>
            </div>
          </div>
        </a>
      </div>

      <div class="col-md-4 col-sm-6 grid-item captain-tower"
      *ngIf="workFilter === 'all' || workFilter === 'captain-tower'">
      <a href="javascript:" class="work-video" (click)="singleGallery.open(0)">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Captain-tower</span>
            <h4 class="title">Three Apps Overview</h4>
            <span class="more-button"><i class="icon-magnifier-add"></i></span>
          </div>
          <div class="thumb">
            <app-gallery #singleGallery [albums]="singleGallery8"></app-gallery>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <div class="col-md-4 col-sm-6 grid-item captain-tower"
      *ngIf="workFilter === 'all' || workFilter === 'captain-tower'">
      <a href="javascript:" class="work-video" (click)="singleGallery.open(0)">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Captain-tower</span>
            <h4 class="title">Elpem Application Dashboard</h4>
            <span class="more-button"><i class="icon-magnifier-add"></i></span>
          </div>
          <div class="thumb">
            <app-gallery #singleGallery [albums]="singleGallery9"></app-gallery>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <div class="col-md-4 col-sm-6 grid-item captain-tower"
      *ngIf="workFilter === 'all' || workFilter === 'captain-tower'">
      <a href="javascript:" class="work-video" (click)="singleGallery.open(0)">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Captain-tower</span>
            <h4 class="title">Three Apps Overview</h4>
            <span class="more-button"><i class="icon-magnifier-add"></i></span>
          </div>
          <div class="thumb">
            <app-gallery #singleGallery [albums]="singleGallery10"></app-gallery>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <div class="col-md-4 col-sm-6 grid-item spookyswap" *ngIf="workFilter === 'all' || workFilter === 'Spookyswap' ">
      <a href="javascript:" class="work-content" data-toggle="modal" (click)="singleGallery.open(0)">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Spookyswap</span>
            <h4 class="title">SpookySwap Home</h4>
            <span class="more-button"><i class="icon-magnifier-add"></i></span>
          </div>
          <div class="thumb">
            <app-gallery #singleGallery [albums]="singleGallery4"></app-gallery>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>


    <div class="col-md-4 col-sm-6 grid-item spookyswap" *ngIf="workFilter === 'all' || workFilter === 'Spookyswap' ">
      <a href="javascript:" class="work-content" data-toggle="modal" (click)="singleGallery.open(0)">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Spookyswap</span>
            <h4 class="title">SpookySwap Staking Interface</h4>
            <span class="more-button"><i class="icon-magnifier-add"></i></span>
          </div>
          <div class="thumb">
            <app-gallery #singleGallery [albums]="singleGallery5"></app-gallery>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>
    <div class="col-md-4 col-sm-6 grid-item spookyswap" *ngIf="workFilter === 'all' || workFilter === 'Spookyswap' ">
      <a href="javascript:" class="work-content" data-toggle="modal" (click)="singleGallery.open(0)">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Spookyswap</span>
            <h4 class="title">SpookySwap Swap Interface</h4>
            <span class="more-button"><i class="icon-magnifier-add"></i></span>
          </div>
          <div class="thumb">
            <app-gallery #singleGallery [albums]="singleGallery6"></app-gallery>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>



 

    <!-- portfolio item -->
    <div class="col-md-4 col-sm-6 grid-item fantom-tribune"
      *ngIf="workFilter === 'all' || workFilter === 'fantom-tribune'">
      <a href="javascript:" class="work-image" (click)="singleGallery.open(0)">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Fantom-tribune</span>
            <h4 class="title">Welcome Screen Fantom Tribune</h4>
            <span class="more-button"><i class="icon-magnifier-add"></i></span>
          </div>
          <div class="thumb">
            <app-gallery #singleGallery [albums]="singleGallery1"></app-gallery>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>
<!-- 
    <app-ui-modal #audioModal dialogClass="modal-dialog-centered work-modal audio-modal" [hideHeader]="true"
      [hideFooter]="true">
      <div class="app-modal-body">
        <iframe id="music-on" class="mfp-iframe" frameborder="0" allowfullscreen="" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/385068290&color=%23ff5500&
auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&
show_teaser=true&visual=true"></iframe>
      </div>
    </app-ui-modal> -->



    <!-- portfolio item -->
    <div class="col-md-4 col-sm-6 grid-item eleveur-connect captain-tower"
      *ngIf="workFilter === 'all' || workFilter === 'fantom-tribune'">
      <a href="javascript:" target="_blank" (click)="singleGallery.open(0)">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Fantom-Tribune</span>
            <h4 class="title">Page Token Information</h4>
            <span class="more-button"><i class="icon-magnifier-add"></i></span>
          </div>
          <div class="thumb">
            <app-gallery #singleGallery [albums]="singleGallery11"></app-gallery>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

          <!-- portfolio item -->
          <div class="col-md-4 col-sm-6 grid-item eleveur-connect captain-tower"
          *ngIf="workFilter === 'all' || workFilter === 'fantom-tribune'">
          <a href="javascript:" target="_blank" (click)="singleGallery.open(0)">
            <div class="portfolio-item rounded shadow-dark">
              <div class="details">
                <span class="term">Fantom-Tribune</span>
                <h4 class="title">Page Top Ranked</h4>
                <span class="more-button"><i class="icon-magnifier-add"></i></span>
              </div>
              <div class="thumb">
                <app-gallery #singleGallery [albums]="singleGallery12"></app-gallery>
                <div class="mask"></div>
              </div>
            </div>
          </a>
        </div>
  </div>



  <!-- more button -->
  <div class="load-more text-center mt-4">
    <a href="javascript:" class="btn btn-default"><i class="fas fa-spinner"></i> Load more</a>
    <!-- numbered pagination (hidden for infinite scroll) -->
    <ul class="portfolio-pagination list-inline d-none">
      <li class="list-inline-item">1</li>
      <li class="list-inline-item"><a href="javascript:">2</a></li>
    </ul>
  </div>
</div>