<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Experiences</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-6">
      <!-- timeline wrapper -->
      <div class="timeline edu rounded bg-white shadow-dark padding-30 overflow-hidden">
        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">Co-Founder, Éleveur Connect | December 2022 - Present
            </span>
            <h3 class="title">Full-Stack Developer</h3>


            <div class="section">
              <h5 class="sousTitle">Description of the Company</h5>
              <p>Éleveur Connect is an innovative platform dedicated to the automatic creation of websites for canine
                and feline breeders. It offers a comprehensive solution that allows breeders to design and easily
                customize their websites through an intuitive user interface.</p>
            </div>

            <div class="section">
              <h5 class="sousTitle">My Role and Achievements</h5>
              <ul>
                <li>Developed user account creation features, allowing breeders to register and log in.</li>
                <li>Implemented complete website customization features, including style, color, images, and other
                  visual elements, for both professionals and hobbyists.</li>
                <li>Created the admin interface with a ticketing system to facilitate communication between users and
                  the support team.</li>
                <li>Added features enabling users to manage their websites, add animals (cats, dogs) based on their
                  breeding type, include puppies and kittens, and receive messages from clients.</li>
                <li>Implemented a detailed statistics system for users to track their website performance.</li>
                <li>Utilized server-side rendering (SSR) to improve site performance and search engine visibility.</li>
                <li>Optimized performance using lazy loading, Angular signals, and Angular Universal to enhance the
                  speed and efficiency of the platform.</li>
              </ul>
            </div>

            <p>
              <strong>Technologies used :</strong> Angular (15 to 18), RxJS, SSR, Azure SQL Database, ASP.NET,
              Angular Material
            </p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
          <div class="content">
            <span class="time">Co-Founder, Fantom Tribune | March 2022 - December 2022
            </span>
            <h3 class="title">Full-Stack Developer</h3>

            <div class="section">
              <h5 class="sousTitle">Description of the Company</h5>
              <p>Fantom Tribune is a company specializing in showcasing new projects on the Fantom blockchain. It offers
                a promotion system based on user interactions and complete management of crypto and NFT projects.</p>
            </div>

            <div class="section">
              <h5 class="sousTitle">Creation and Development of the Platform</h5>
              <ul>
                <li>Designed and developed a Web 3.0 platform using the MERN stack (MongoDB, Express.js, React.js,
                  Node.js) dedicated to promoting crypto and NFT projects on the Fantom blockchain.</li>
                <li>Implemented features allowing users to register, log in, and submit their Web 3.0 projects, whether
                  they are tokens or NFTs.</li>
              </ul>
            </div>

            <div class="section">
              <ul>
                <li>Created an admin interface to manage users and projects, including the approval, modification, and
                  deletion of submitted projects.</li>
                <li>Established criteria and validation processes to ensure that only projects meeting certain
                  conditions are approved.</li>
              </ul>
            </div>

            <div class="section">
              <ul>
                <li>Integrated the CoinMarketCap API to provide real-time data on prices, market cap, and other metrics.
                </li>
                <li>Used subgraphs to extract data from projects not yet listed on CoinMarketCap directly from
                  SpookySwap, the Fantom blockchain Dex.</li>
              </ul>
            </div>

            <div class="section">
              <ul>
                <li>Included Twitter iframes to highlight the latest news on tokens and price charts via Coinbrain.</li>
                <li>Developed an algorithm for promoting projects based on user interactions and votes.</li>
              </ul>
            </div>

            <div class="section">
              <ul>
                <li>Implemented a 'Promoted' section allowing users to feature their tokens through a paid service.</li>
              </ul>
            </div>

            <div class="section">
              <ul>
                <li>Implemented a captcha system for registration and voting to minimize bot interference in the project
                  promotion algorithm.</li>
              </ul>
            </div>

            <div class="section">
              <ul>
                <li>Integrated the Paintswap API to keep track of various "floor prices," price variations, and other
                  relevant information on NFTs listed on the platform.</li>
              </ul>
            </div>
            <p><strong>Technologies used :</strong> React.js, MongoDB, Express.js, Node.js, Bootstrap.</p>
          </div>
        </div>

    

        <!-- main line -->
        <span class="line"></span>
      </div>
    </div>

    <div class="col-md-6">
      <!-- responsive spacer -->
      <div class="spacer-30 d-md-none d-lg-none"></div>







      <!-- timeline wrapper -->
      <div class="timeline exp bg-white shadow-dark rounded padding-30 overflow-hidden">


    <!-- timeline item -->
    <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
      <div class="content">
        <span class="time">SpookySwap | Freelance | September 2021 - March 2022
        </span>
        <h3 class="title">Front-End Développeur React & Community Manager</h3>
        <div class="section">
          <h5 class="sousTitle">Description of the Company</h5>
          <p>SpookySwap is a company specializing in decentralized exchanges (DEX) on the Fantom blockchain, allowing users to trade cryptocurrencies securely and swiftly.</p>
      </div>
  
      <div class="section">
        <h5 class="sousTitle">Website Development</h5>
          <ul>
              <li>Contributed to the creation and enhancement of SpookySwap's website, the leading DEX on the Fantom blockchain.</li>
              <li>Implemented design improvements and bug fixes on the user interface using React.js.</li>
              <li>Enabled users to view token prices without connecting their wallets, thus improving accessibility and user experience.</li>
          </ul>
      </div>
  
      <div class="section">
        <h5 class="sousTitle">Community Manager</h5>

          <ul>
              <li>Developed a Telegram bot in Python, utilizing the Telegram API to automate responses to frequently asked questions, thereby reducing response time and improving support efficiency.</li>
              <li>Created and managed the official Telegram group, providing comprehensive technical support for application-related issues, including interface, transaction, and bridge problems.</li>
              <li>Educated a largely novice user base on blockchain best practices and fund security.</li>
              <li>Significantly improved written English skills by interacting with an international community.</li>
          </ul>
      </div>
  
      <div class="section">
          <ul>
              <li>Worked closely with Anyswap to address issues related to bridges between different blockchains.</li>
              <li>Actively participated in the selection and prioritization of community suggestions to influence the roadmap and future product improvements.</li>
          </ul>
      </div>
        <p><strong>Technologies used :</strong> React.js, MongoDB, Express.js, Node.js, Bootstrap, Python
      </div>
    </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">Captain Tower, Paris, France | Full-Time | July 2020 - September 2021
            </span>
            <h3 class="title">Angular Ionic front-end developer</h3>
            <div class="section">
              <h5 class="sousTitle">Description of the Company</h5>
              <p>Captain Tower is a company specializing in developing digital solutions for real estate professionals.</p>
          </div>
      
          <div class="section">
            <h5 class="sousTitle">Applications Development</h5>
              <ul>
                  <li>Focused primarily on the administrator part of the tool, developed in Angular.</li>
                  <li>Designed an interactive data tree to allow modification of backend data directly from the administrator interface.</li>
                  <li>Developed a Postman-like feature integrated into the admin dashboard, enabling the creation, testing, and execution of HTTP requests to facilitate debugging and management of backend services.</li>
              </ul>
          </div>
      
          <div class="section">
              <ul>
                  <li>Led the upgrade from Angular version 6 to version 12, ensuring compatibility and uninterrupted service deployment.</li>
              </ul>
          </div>
      
          <div class="section">
              <ul>
                  <li>Utilized Angular Material for UI, and worked in an Agile environment following Scrum methodology.</li>
                  <li>Managed source code using SourceTree and Bitbucket, and tracked tasks using Jira.</li>
              </ul>
          </div>
      
          <div class="section">
              <ul>
                  <li>Worked on both web and mobile applications using Angular and Ionic.</li>
                  <li>Performed bug tracking and debugging tasks to improve application stability and performance.</li>
                  <li>Used RxJS to manage asynchronous operations.</li>
                  <li>Employed Angular Material to enhance the user interface by integrating material components and optimizing the user experience.</li>
              </ul>
          </div>
            <p><strong>Technologies used :</strong> Angular, Angular Material, RxJS, Ionic, SourceTree, Bitbucket, Jira
            </p>

          </div>
        </div>

    

        <!-- timeline item -->
        <!--       <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
          <div class="content">
            <span class="time">2013 - 2009</span>
            <h3 class="title">Back-End Developer</h3>
            <p>Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.</p>
          </div>
        </div> -->

        <!-- main line -->
        <span class="line"></span>
      </div>
    </div>
  </div>
</div>