<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Services</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center text-light shadow-blue bg-blue">
        <img src="assets/images/service-1.svg" alt="UI/UX design" />
        <h3 class="mb-3 mt-0">UI/UX design</h3>
        <p class="mb-0">Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center shadow-yellow bg-yellow">
        <img src="assets/images/service-2.svg" alt="UI/UX design" />
        <h3 class="mb-3 mt-0">Web Development</h3>
        <p class="mb-0">Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center text-light shadow-pink bg-pink">
        <img src="assets/images/service-3.svg" alt="UI/UX design" />
        <h3 class="mb-3 mt-0">Photography</h3>
        <p class="mb-0">Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.</p>
      </div>
    </div>
  </div>

  <div class="mt-5 text-center">
    <p class="mb-0">Looking for a custom job? <a href="javascript:" (click)="scrollTo('contact')">Click here</a> to contact me! 👋</p>
  </div>
</div>
