<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">About Me</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-3">
      <div class="text-center text-md-left">
        <!-- avatar image -->
        <img src="assets/images/profile4.png" alt="Bolby" />
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-9 triangle-left-md triangle-top-sm">
      <div class="rounded bg-white shadow-dark padding-30">
        <div class="row">
          <div class="col-md-6">
            <!-- about text -->
            <p>
              I am Amiel Zeitoun, a Web Developer in Paris.

              With 4 years of Angular experience, I build high-performance web solutions. Passionate about blockchain, I seek a full-time position to apply my expertise and contribute to innovative projects.            </p>
            <div class="mt-3">
              <a href="assets/cv/cv_Zeitoun_Amiel.pdf"  download="cv_Zeitoun_Amiel.pdf" class="btn btn-default">Download CV</a>
            </div>
            <div class="spacer-30 d-md-none d-lg-none"></div>
          </div>
          <div class="col-md-6">
            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Development Front End</h4>
                <span class="float-right">95%</span>
              </div>
              <ngb-progressbar type="warning" [value]="85"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">UI/UX design</h4>
                <span class="float-right">70%</span>
              </div>
              <ngb-progressbar type="danger" [value]="95"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Development Back</h4>
                <span class="float-right">60%</span>
              </div>
              <ngb-progressbar type="primary" [value]="70"></ngb-progressbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- row end -->

  <div class="spacer-70"></div>

 
</div>
