import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.scss']
})
export class WorksComponent implements OnInit {
  public workFilter: string;
  public singleGallery1: any;
  public singleGallery2: any;
  public singleGallery3: any;
  public singleGallery4: any;
  public singleGallery5: any;
  public singleGallery6: any;
  public singleGallery7: any;
  public singleGallery8: any;
  public singleGallery9: any;
  public singleGallery10: any;
  public singleGallery11: any;
  public singleGallery12: any;

  public albums: any = [];

  videoObject: Array<object> = [
    {
      video: 'https://www.youtube.com/watch?v=qf9z4ulfmYw',
      posterImage: 'assets/images/works/3.svg'
    }
  ];

  constructor() {
    this.workFilter = 'eleveur-connect';
    this.singleGallery1 = [
      {
        src: 'assets/images/works/ftm2.JPG',
        caption: 'Welcome Screen Fantom Tribune',
        thumb: 'assets/images/works/ftm2.JPG'
      }
    ];

    this.singleGallery2 = [
      {
        src: 'assets/images/works/eleveur1.JPG',
        caption: 'Welcome Screen Fantom Tribune',
        thumb: 'assets/images/works/eleveur1.JPG'
      }
    ];

    this.singleGallery3 = [
      {
        src: 'assets/images/works/eleveur4.JPG',
        caption: 'Welcome Screen Fantom Tribune',
        thumb: 'assets/images/works/eleveur4.JPG'
      }
    ];

    this.singleGallery4 = [
      {
        src: 'assets/images/works/spook1.png',
        caption: 'Welcome Screen Fantom Tribune',
        thumb: 'assets/images/works/spook1.png'
      }
    ];

    this.singleGallery5 = [
      {
        src: 'assets/images/works/spook2.JPG',
        caption: 'Welcome Screen Fantom Tribune',
        thumb: 'assets/images/works/spook2.JPG'
      }
    ];

    this.singleGallery6 = [
      {
        src: 'assets/images/works/spook3.png',
        caption: 'Welcome Screen Fantom Tribune',
        thumb: 'assets/images/works/spook3.png'
      }
    ];

    this.singleGallery7 = [
      {
        src: 'assets/images/works/eleveur3.JPG',
        caption: 'Welcome Screen Fantom Tribune',
        thumb: 'assets/images/works/eleveur3.JPG'
      }
    ];

    this.singleGallery8 = [
      {
        src: 'assets/images/works/captain1.JPG',
        caption: 'Welcome Screen Fantom Tribune',
        thumb: 'assets/images/works/captain1.JPG'
      }
    ];

    this.singleGallery9 = [
      {
        src: 'assets/images/works/captain2.JPG',
        caption: 'Welcome Screen Fantom Tribune',
        thumb: 'assets/images/works/captain2.JPG'
      }
    ];

    this.singleGallery10 = [
      {
        src: 'assets/images/works/captain3.JPG',
        caption: 'Welcome Screen Fantom Tribune',
        thumb: 'assets/images/works/captain3.JPG'
      }
    ];

    this.singleGallery11 = [
      {
        src: 'assets/images/works/ftm1.png',
        caption: 'Welcome Screen Fantom Tribune',
        thumb: 'assets/images/works/ftm1.png'
      }
    ];

    this.singleGallery12 = [
      {
        src: 'assets/images/works/ftm3.png',
        caption: 'Welcome Screen Fantom Tribune',
        thumb: 'assets/images/works/ftm3.png'
      }
    ];

    for (let i = 5; i >= 1; i--) {
      const album = {
        src: 'assets/images/works/'+'ftm'+ i + '.JPG',
        caption: 'Image ' + i + ' caption here',
        thumb: 'assets/images/works/' + i + '.svg'
      };

      this.albums.push(album);
    }
  }

  ngOnInit(): void { }

  onChange(e) {
    this.workFilter = e.target.value;
  }
}
