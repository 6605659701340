<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Skills & Expertise</h2>

  <!-- <div class="spacer-60"></div> -->

  <!-- testimonials wrapper -->
<!--   <div class="testimonials-wrapper">
    <ngb-carousel *ngIf="images" [interval]="5000" [showNavigationArrows]="false">
      <ng-template ngbSlide>
        <div class="testimonial-item text-center mx-auto">
          <div class="thumb mb-3 mx-auto">
            <img src="assets/images/avatar-3.svg" alt="customer-name" />
          </div>
          <h4 class="mt-3 mb-0">John Doe</h4>
          <span class="subtitle">Product designer at Dribbble</span>
          <div class="bg-white padding-30 shadow-dark rounded triangle-top position-relative mt-4">
            <p class="mb-0">
              I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍
            </p>
          </div>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="testimonial-item text-center mx-auto">
          <div class="thumb mb-3 mx-auto">
            <img src="assets/images/avatar-3.svg" alt="customer-name" />
          </div>
          <h4 class="mt-3 mb-0">John Doe</h4>
          <span class="subtitle">Product designer at Dribbble</span>
          <div class="bg-white padding-30 shadow-dark rounded triangle-top position-relative mt-4">
            <p class="mb-0">
              I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍
            </p>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
  </div> -->

  <div class="row" style="margin-top: 20px">
    <div class="col-md-3 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <img class= "imgLogo" [src]="themeType === 'dark' ? 'assets/images/logo1.png' : 'assets/images/logo1.png'" alt="client-name" />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <img class= "imgLogo"[src]="themeType === 'dark' ? 'assets/images/logo2.png' : 'assets/images/logo2.png'" alt="client-name" />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <img class= "imgLogo" [src]="themeType === 'dark' ? 'assets/images/logo3.png' : 'assets/images/logo3.png'" alt="client-name" />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <img class= "imgLogo" [src]="themeType === 'dark' ? 'assets/images/logo4.png' : 'assets/images/logo4.png'" alt="client-name" />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <img class= "imgLogo" [src]="themeType === 'dark' ? 'assets/images/logo5.png' : 'assets/images/logo5.png'" alt="client-name" />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <img class= "imgLogo" [src]="themeType === 'dark' ? 'assets/images/logo6.png' : 'assets/images/logo6.png'" alt="client-name" />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <img class= "imgLogo" [src]="themeType === 'dark' ? 'assets/images/logo7.png' : 'assets/images/logo7.png'" alt="client-name" />
        </div>
      </div>
    </div>
    <div class="col-md-3 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <img class= "imgLogo" [src]="themeType === 'dark' ? 'assets/images/logo8.png' : 'assets/images/logo8.png'" alt="client-name" />
        </div>
      </div>
    </div>
  </div>
</div>
