<div class="container">
    <!-- section title -->
    <h2 class="section-title wow fadeInUp">Formations</h2>

    <div class="spacer-60"></div>

    <div class="row">
        <div class="col-md-12">
            <!-- timeline wrapper -->
            <div class="timeline edu rounded bg-white shadow-dark padding-30 overflow-hidden">
                <!-- timeline item -->
                <div class="timeline-container wow fadeInUp">
                    <div class="content">
                        <span class="time">2018 - 2021</span>
                        <h3 class="title">International Master of Science Estiam</h3>
                        <p>Master's degree Web & mobile specializations
                            development at Estiam in Paris</p>
                    </div>
                </div>

                <!-- timeline item -->
                <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
                    <div class="content">
                        <span class="time">2015 - 2018</span>
                        <h3 class="title">Licence Supinfo</h3>
                        <p>
                            Expert licence
                            in Computer Science and Systems
                            of information</p>
                    </div>
                </div>

                <!-- timeline item -->
                <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
                    <div class="content">
                        <span class="time">2014 - 2015</span>
                        <h3 class="title">Baccalauréat Scientifique</h3>
                        <p>Scientific Baccalaureate obtained at the Lycée Charles de Gaulles in Longperrier, specializing in engineering science</p>
                    </div>
                </div>

                <!-- main line -->
                <span class="line"></span>
            </div>
        </div>

    
    </div>
</div>