<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Latest Posts</h2>

  <div class="spacer-60"></div>

  <div class="row blog-wrapper">
    <!-- First blog item -->
    <div class="col-md-4">
      <div class="blog-item rounded bg-white shadow-dark wow fadeIn">
        <div class="thumb">
          <a href="javascript:">
            <span class="category">Master's thesis</span>
          </a>
          <a href="assets/images/blog/Zeitoun-Amiel-Memoire.pdf" download="Zeitoun-Amiel-Memoire.pdf">
            <img class="blog-img" src="assets/images/memoir.jpg" alt="blog-title" />
          </a>
        </div>
        <div class="details">
          <h4 class="my-0 title">
            <a href="javascript:">How smart contracts can they revolutionize our daily</a>
          </h4>
          <ul class="list-inline meta mb-0 mt-2">
            <li class="list-inline-item">1 July, 2021</li>
            <li class="list-inline-item">Amiel</li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Second blog item (duplicate) -->
    <div class="col-md-4">
      <div class="blog-item rounded bg-white shadow-dark wow fadeIn">
        <div class="thumb">
          <a href="javascript:">
            <span class="category">Fantom Tribune #6</span>
          </a>
          <a href="https://medium.com/@fantomtribune/fantom-tribune-6-2170bfa6fe5d"  target="_blank ">
            <img class="blog-img" src="assets/images/blog/Graph-Protocol.webp" alt="blog-title" />
          </a>
        </div>
        <div class="details">
          <h4 class="my-0 title">
            <a href="javascript:">What Thegraph is and why this project is fundamental in our decentralized world</a>
          </h4>
          <ul class="list-inline meta mb-0 mt-2">
            <li class="list-inline-item">Jan 18, 2022</li>
            <li class="list-inline-item">Amiel</li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Third blog item (duplicate) -->
    <div class="col-md-4">
      <div class="blog-item rounded bg-white shadow-dark wow fadeIn">
        <div class="thumb">
          <a href="javascript:">
            <span class="category">Fantom Tribune #5</span>
          </a>
          <a href="https://medium.com/@fantomtribune/fantom-tribune-5-252492568b41"  target="_blank ">
            <img class="blog-img" src="assets/images/blog/1_GA0EglVAAJ1JKYQXiXMlVg@2x.webp"
              alt="blog-title" />
          </a>
        </div>
        <div class="details">
          <h4 class="my-0 title">
            <a href="javascript:">When there's $TOMB there's $LIF3.</a>
          </h4>
          <ul class="list-inline meta mb-0 mt-2">
            <li class="list-inline-item">Jan 13, 2022</li>
            <li class="list-inline-item">Amiel</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="blog-item rounded bg-white shadow-dark wow fadeIn">
        <div class="thumb">
          <a href="javascript:">
            <span class="category">Fantom Tribune #4</span>
          </a>
          <a href="https://medium.com/@fantomtribune/the-4-edition-of-the-fantom-tribune-is-live-cb96be1b561a"  target="_blank ">
            <img class="blog-img" src="assets/images/blog/1_LvRkvuSwUrTUAVqud1lrBg@2x.webp"
              alt="blog-title" />
          </a>
        </div>
        <div class="details">
          <h4 class="my-0 title">
            <a href="javascript:">What Can You Expect from Rubic.exchange, Blocknative Insights, and Setting Up Your Ledger S/X with Fantom?</a>
          </h4>
          <ul class="list-inline meta mb-0 mt-2">
            <li class="list-inline-item">Dec 14, 2021</li>
            <li class="list-inline-item">Amiel</li>
          </ul>
        </div>
      </div>
    </div>

    
    <div class="col-md-4">
      <div class="blog-item rounded bg-white shadow-dark wow fadeIn">
        <div class="thumb">
          <a href="javascript:">
            <span class="category">Fantom Tribune #3</span>
          </a>
          <a href="https://medium.com/@fantomtribune/fantom-tribune-3-7be3770bd1f7"  target="_blank ">
            <img class="blog-img" src="assets/images/blog/1_uVsCWxfYcnQi2anUU5tFfw@2x.webp"
              alt="blog-title" />
          </a>
        </div>
        <div class="details">
          <h4 class="my-0 title">
            <a href="javascript:">What Can You Expect from Rubic.exchange, Blocknative Insights, and Setting Up Your Ledger S/X with Fantom?</a>
          </h4>
          <ul class="list-inline meta mb-0 mt-2">
            <li class="list-inline-item">Dec 2, 2021</li>
            <li class="list-inline-item">Amiel</li>
          </ul>
        </div>
      </div>
    </div>


        
    <div class="col-md-4">
      <div class="blog-item rounded bg-white shadow-dark wow fadeIn">
        <div class="thumb">
          <a href="javascript:">
            <span class="category">Fantom Tribune #2</span>
          </a>
          <a href="https://medium.com/@fantomtribune/fantom-tribune-2-3916bd8cba1b"  target="_blank ">
            <img class="blog-img" src="assets/images/blog/1_kEtbkAmums30HD64rNK0Rg@2x.webp"
              alt="blog-title" />
          </a>
        </div>
        <div class="details">
          <h4 class="my-0 title">
            <a href="javascript:">"What Are the Highlights of Fantomstarter's First IDO Cohort, the SpookySwap and Mai Finance Partnership, and Tomb Finance's New Validator Node?"</a>
          </h4>
          <ul class="list-inline meta mb-0 mt-2">
            <li class="list-inline-item">Nov 22, 2021</li>
            <li class="list-inline-item">Amiel</li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</div>